import React, { useState } from 'react'
import Editor from './Editor'
import { editPrices, editMenuHTML, editEmailInstructions } from '../../lib/admin'
import { RestaurantState } from '../../state'

const EditMenu = () => {
  const { restaurant } = RestaurantState.useContainer()
  const [for2, setFor2] = useState(restaurant.menuData.forTwoPrice)
  const [for4, setFor4] = useState(restaurant.menuData.forFourPrice)
  const [for6, setFor6] = useState(restaurant.menuData.forSixPrice)
  const [html1, setHtml1] = useState(restaurant.menuData.tueHtml)
  const [html2, setHtml2] = useState(restaurant.menuData.thuHtml)
  const [html3, setHtml3] = useState(restaurant.tueEmailMessage)
  const [html4, setHtml4] = useState(restaurant.thuEmailMessage)

  return (
    <div className="bg-gray-200 min-h-screen p-5 text-gray-900">
      <div className="flex">
        <div className="w-full lg:w-3/5">
          <div className="h-64 mb-32">
            <div className="flex items-center justify-between">
              <p className="text-lg mb-1 font-bold">Edit Tuesday's Menu</p>
              <button
                onClick={() => {
                  editMenuHTML(html1, 'tue')
                    .then(() => alert('Successfully updated Tuesdays menu!'))
                    .catch(err => {
                      console.error(err)
                      alert('Could not update menu.')
                    })
                }}
                className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
              >
                Save
              </button>
            </div>
            <Editor onChange={html => setHtml1(html)} initialHtml={html1} />
          </div>
          <div className="h-64 mb-32">
            <div className="flex items-center justify-between">
              <p className="text-lg mb-1 font-bold">Edit Thursday's Menu</p>
              <button
                onClick={() => {
                  editMenuHTML(html2, 'thu')
                    .then(() => alert('Successfully updated Thursdays menu!'))
                    .catch(err => {
                      console.error(err)
                      alert('Could not update menu.')
                    })
                }}
                className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
              >
                Save
              </button>
            </div>
            <Editor onChange={html => setHtml2(html)} initialHtml={html2} />
          </div>
          {/*  change day email messages  */}
          <div className="h-64 mb-32">
            <div className="flex items-center justify-between">
              <p className="text-lg mb-1 font-bold">Edit Tuesday's Email/Cooking Instructions</p>
              <button
                onClick={() => {
                  editEmailInstructions(html3, 'tue')
                    .then(() => alert('Successfully updated Tuesdays email message!'))
                    .catch(err => {
                      console.error(err)
                      alert('Could not update message.')
                    })
                }}
                className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
              >
                Save
              </button>
            </div>
            <Editor onChange={html => setHtml3(html)} initialHtml={html3} />
          </div>
          <div className="h-64 mb-32">
            <div className="flex items-center justify-between">
              <p className="text-lg mb-1 font-bold">Edit Thursday's Email/Cooking Instructions</p>
              <button
                onClick={() => {
                  editEmailInstructions(html4, 'thu')
                    .then(() => alert('Successfully updated Thursdays email message!'))
                    .catch(err => {
                      console.error(err)
                      alert('Could not update message.')
                    })
                }}
                className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
              >
                Save
              </button>
            </div>
            <Editor onChange={html => setHtml4(html)} initialHtml={html4} />
          </div>
        </div>
        <div className="w-full lg:w-2/5 flex flex-col items-center">
          <span className="text-xl mb-4">Edit prices (in dollars):</span>
          <span className="mb-1 text-gray-700">For Two</span>
          <input
            onChange={e => setFor2(e.target.value)}
            type="number"
            value={for2}
            placeholder="For Two Price"
            className="mb-10 p-3 rounded"
          />
          <span className="mb-1 text-gray-700">For Four</span>
          <input
            onChange={e => setFor4(e.target.value)}
            type="number"
            value={for4}
            placeholder="For Four Price"
            className="mb-10 p-3 rounded"
          />
          <span className="mb-1 text-gray-700">For Six</span>
          <input
            onChange={e => setFor6(e.target.value)}
            type="number"
            value={for6}
            placeholder="For Six Price"
            className="mb-10 p-3 rounded"
          />
          <button
            onClick={() => {
              editPrices(for2, for4, for6)
                .then(() => alert('Successfully updated prices!'))
                .catch(err => {
                  console.error(err)
                  alert('Could not update prices.')
                })
            }}
            className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
          >
            Save Prices
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditMenu
