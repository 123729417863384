import React from 'react'
import { Redirect } from 'react-router-dom'
import { MainLayout, Card } from '../components/common'

const Summary = ({ order, day }) => {
  const { lastName, email, phone, specialInstructions, deliveryMethod, orderData, code } = order

  return orderData.forTwo > 0 || orderData.forFour > 0 || orderData.forSix > 0 ? (
    <>
      <div className="mb-5 border-t p-2 border-gray-500 text-lg">
        <p className="font-semibold">{day}:</p>
        {lastName && <p>Last Name: {lastName}</p>}
        {email && <p>Email: {email}</p>}
        {phone && <p>Phone: {phone}</p>}
        {deliveryMethod && <p>Delivery Method: {deliveryMethod}</p>}
        {specialInstructions && <p>Special Instructions: {specialInstructions}</p>}
        {orderData.forTwo > 0 && <p>Dinner for two: {orderData.forTwo}</p>}
        {orderData.forFour > 0 && <p>Dinner for four: {orderData.forFour}</p>}
        {orderData.forSix > 0 && <p>Dinner for six: {orderData.forSix}</p>}
        <p>
          Order #: <span className="font-bold">{code}</span>
        </p>
        {/* {deliveryMethod === 'Delivery' && (
          <p className="mt-2">You were charged ${(subtotalDelivery + deliveryFee + tip).toFixed(2)}</p>
        )} */}
        {deliveryMethod === 'Takeout' && (
          <>
            <p className="my-2">You will pay ${orderData.takeoutOwed} in the restaurant at pickup time.</p>
            <p>
              Pick up is between the hours of 2:45 and 5:15 at Marigolds, 426 Springfield Avenue, in Summit.
            </p>
          </>
        )}
      </div>
    </>
  ) : null
}

const ThankYou = ({ history }) => {
  if (!history.location.state) {
    return <Redirect to="/marigolds" />
  }

  const { createdTuesday, createdThursday } = history.location.state

  return (
    <MainLayout>
      <Card>
        <div className="text-center">
          <h1 className="text-2xl font-bold">Thank you!</h1>
          <p className="text-xl mb-1">We received your order!</p>
          <p className="text-xl mb-4">You may want to print this page for confirmation.</p>
          {createdTuesday && <Summary order={createdTuesday} day="Tuesday" />}
          {createdThursday && <Summary order={createdThursday} day="Thursday" />}
        </div>
      </Card>
    </MainLayout>
  )
}

export default ThankYou
