import React, { useState } from 'react'
import Editor from './Editor'
import { editInventories, sendMassEmail, wipeOrders } from '../../lib/admin'
import { RestaurantState } from '../../state'

const NewWeek = () => {
  const { restaurant } = RestaurantState.useContainer()
  const [tueInventory, setTueInventory] = useState(restaurant.tueInventoryAmount)
  const [thuInventory, setThuInventory] = useState(restaurant.thuInventoryAmount)
  const [emailHtml, setEmailHtml] = useState('')
  const [subject, setSubject] = useState('')

  return (
    <div className="bg-gray-200 min-h-screen p-5 text-gray-900">
      <div className="flex flex-col md:flex-row">
        <div className="w-full lg:w-2/5 flex flex-col items-center">
          <span className="text-lg font-bold mb-4">Wipe orders:</span>
          <button
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to do this? This will delete all existing orders in the system.'
                )
              ) {
                if (window.confirm('Please confirm one more time to make sure!')) {
                  wipeOrders()
                    .then(() =>
                      alert(
                        'Successfully cleared all orders from the system. You can start a new week now! Dont forget to reset inventories.'
                      )
                    )
                    .catch(err => {
                      console.error(err)
                      alert(err.response?.data?.error || 'Could not wipe orders.')
                    })
                }
              }
            }}
            className="bg-tap hover:bg-orange-800 text-white p-2 rounded shadow mb-10"
          >
            Wipe Existing Orders
          </button>

          <span className="text-lg font-bold mb-4">Set total inventories:</span>

          <span className="mb-1 text-gray-700">Set Tuesday</span>
          <input
            onChange={e => setTueInventory(e.target.value)}
            type="number"
            value={tueInventory}
            placeholder="Tuesday inventory"
            className="mb-4 p-2 rounded"
          />

          <span className="mb-1 text-gray-700">Set Thursday</span>
          <input
            onChange={e => setThuInventory(e.target.value)}
            type="number"
            value={thuInventory}
            placeholder="Thursday inventory"
            className="mb-4 p-2 rounded"
          />

          <button
            onClick={() => {
              editInventories({
                tueInventory,
                thuInventory
              })
                .then(() => alert('Successfully updated new inventories!'))
                .catch(err => {
                  console.error(err)
                  alert('Could not update inventories.')
                })
            }}
            className="mb-2 bg-tap hover:bg-orange-800 text-white p-2 rounded shadow mb-10"
          >
            Save Inventories
          </button>
        </div>
        <div className="w-4/5 h-56">
          <p className="font-bold text-lg mb-3">Send mass emails:</p>
          <div className="flex justify-between mb-2 items-center">
            <input
              placeholder="Subject"
              type="text"
              className="p-2 rounded flex-grow mr-2"
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
            <button
              onClick={() => {
                sendMassEmail({
                  emailHtml,
                  subject
                })
                  .then(() => alert('Successfully sent email to all subscribed customers!'))
                  .catch(err => {
                    console.error(err)
                    alert(err.response?.data?.error || 'Could not send emails.')
                  })
              }}
              className="bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
            >
              Send Emails
            </button>
          </div>
          <Editor onChange={html => setEmailHtml(html)} initialHtml={emailHtml} />
        </div>
      </div>
    </div>
  )
}

export default NewWeek
