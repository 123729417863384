import React from 'react'
import Cart from './CartState'
import Restaurant from './RestaurantState'
import Auth from './AuthState'

const AppProviders = ({ children }) => {
  return (
    <Auth.Provider>
      <Restaurant.Provider>
        <Cart.Provider>{children}</Cart.Provider>
      </Restaurant.Provider>
    </Auth.Provider>
  )
}

export default AppProviders
