import axios from 'axios'

function getToken() {
  const token = localStorage.getItem('marigolds')
  return token
}

export async function fetchOrders(day) {
  try {
    const { data } = await axios.get(`/api/admin/orders?day=${day}`, {
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    return data
  } catch (err) {
    throw err
  }
}

export async function editGreeting(greetingText) {
  try {
    await axios.post(
      `/api/admin/edit-greeting`,
      { greetingText },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function editPrices(forTwoPrice, forFourPrice, forSixPrice) {
  try {
    await axios.post(
      `/api/admin/edit-prices`,
      { forTwoPrice, forFourPrice, forSixPrice },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function editMenuHTML(html, menu) {
  try {
    await axios.post(
      `/api/admin/edit-menu`,
      { html, menu },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function editInventories({ tueInventory, thuInventory }) {
  try {
    await axios.post(
      `/api/admin/edit-inventories`,
      {
        tueInventory,
        thuInventory
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function sendMassEmail({ emailHtml, subject }) {
  try {
    await axios.post(
      `/api/admin/send-mass-email`,
      {
        emailHtml,
        subject
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function wipeOrders() {
  try {
    await axios.post(
      `/api/admin/wipe-orders`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function editEmailInstructions(html, day) {
  try {
    await axios.post(
      `/api/admin/set-email-message`,
      { html, day },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}

export async function deleteEmail(email) {
  try {
    await axios.post(
      '/api/misc/delete-email',
      { email },
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )
  } catch (err) {
    throw err
  }
}
