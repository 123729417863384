import React from 'react'
import { RestaurantState } from '../../state'
import { Card } from '../common'
import MarigoldsLogo from '../../assets/img/marigolds.png'

const Greeting = () => {
  const { restaurant } = RestaurantState.useContainer()

  return (
    <Card>
      <div className="flex items-center">
        <img
          style={{ height: '150px' }}
          className="hidden md:block mb-2 md:mb-0 pr-3"
          src={MarigoldsLogo}
          alt="Logo"
        />
        <div className="w-full text-center md:text-left">
          <h1 className="font-bold text-xl md:text-3xl mb-2">
            {restaurant && restaurant.menuData.headerText}
          </h1>
          <p className="text-lg">{restaurant && restaurant.menuData.headerDescription}</p>
        </div>
      </div>
    </Card>
  )
}

export default Greeting
