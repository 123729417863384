import React, { useState } from 'react'
import { editGreeting, deleteEmail } from '../../lib/admin'
import { RestaurantState } from '../../state'
import { DualRing } from 'react-css-spinners'

const EditGreeting = () => {
  const { restaurant } = RestaurantState.useContainer()
  const [greeting, setGreeting] = useState(restaurant.menuData.headerDescription)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const onRemoveEmail = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await deleteEmail(email)
      setLoading(false)
      setEmail('')
      alert('Successfully deleted email from list.')
    } catch (err) {
      setLoading(false)
      console.error(err)
      alert('Something went wrong.')
    }
  }
  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="p-5 text-gray-900 text-center">
        <p className="font-bold text-lg mb-3">Edit Site Greeting:</p>
        <textarea
          className="w-1/2 p-2 mb-3"
          rows="5"
          value={greeting}
          onChange={e => setGreeting(e.target.value)}
        />
        <button
          className="block bg-tap p-2 rounded shadow text-white mx-auto"
          onClick={() => {
            editGreeting(greeting)
              .then(() => alert('Successfully updated greeting!'))
              .catch(err => {
                console.error(err)
                alert('Could not update. Contact us if this continues...')
              })
          }}
        >
          Save Greeting
        </button>
      </div>
      <br />
      <br />

      <form className="flex flex-col items-center justify-center w-full text-center" onSubmit={onRemoveEmail}>
        <p className="font-bold text-lg mb-3">Delete Email From List:</p>
        <input
          required
          type="email"
          placeholder="Enter email to remove..."
          className="text-center rounded p-2 text-lg w-1/4 mb-3"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        {loading ? (
          <>
            <DualRing color="red" />
            <p className="text-lg text-center">Please wait...</p>
          </>
        ) : (
          <button type="submit" className="bg-tap text-white text-lg p-2 rounded hover:bg-orange-600">
            Delete Email
          </button>
        )}
      </form>
    </div>
  )
}

export default EditGreeting
