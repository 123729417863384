import axios from 'axios'

export const submitOrder = async ({ token = null, cart, history }) => {
  try {
    if (cart.orderDetails.name === '' || cart.orderDetails.email === '') {
      return alert('Name and email are required.')
    }

    if (token && cart.orderDetails.phone === '') {
      return alert('A phone number is required.')
    }

    if (token && cart.orderDetails.tue.address === '' && cart.orderDetails.thu.address === '') {
      return alert('An address is required.')
    }

    if (token && cart.orderDetails.tue.city === '' && cart.orderDetails.thu.city === '') {
      return alert('A city is required.')
    }

    if (
      cart.orderDetails.tue.forTwo === 0 &&
      cart.orderDetails.tue.forFour === 0 &&
      cart.orderDetails.tue.forSix === 0 &&
      cart.orderDetails.thu.forTwo === 0 &&
      cart.orderDetails.thu.forFour === 0 &&
      cart.orderDetails.thu.forSix === 0
    ) {
      return alert('You must select a quantity.')
    }

    const { data } = await axios.post('/api/orders/marigolds', {
      token,
      cart,
      type: token ? 'charge' : 'nocharge',
    })

    if (data.status === 'ok') {
      history.push({ pathname: '/thank-you', state: data })
    } else {
      throw new Error('Could not complete order.')
    }
  } catch (err) {
    console.error(err)
    alert(err.response?.data?.error || 'Something went wrong')
  }
}
