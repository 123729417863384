import React, { useState, useEffect } from 'react'
import { fetchOrders } from '../lib/admin'

const PrintView = ({ match }) => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  async function getOrders(day) {
    try {
      setLoading(true)
      const data = await fetchOrders(day)
      setOrders(data.orders)
    } catch (err) {
      console.error(err)
      alert('Could not fetch orders.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'Marigolds Orders'
    getOrders(match.params.day)
  }, [match.params.day])

  return loading ? null : (
    <div className="p-2">
      <h1 className="my-5 text-2xl">Orders for {match.params.day}:</h1>
      <p className="mt-3 mb-5">{orders.length} total orders</p>

      <table className="w-full" style={{ fontSize: '10px' }}>
        <thead>
          <tr>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Delivery Method</th>
            <th>Address</th>
            <th>Special Instructions</th>
            <th>For 2</th>
            <th>For 4</th>
            <th>For 6</th>
            <th>Order #</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => (
            <tr key={i}>
              <td className="border px-4 py-2">{order.lastName}</td>
              <td className="border px-4 py-2">{order.email}</td>
              <td className="border px-4 py-2">{order.phone}</td>
              <td className="border px-4 py-2">
                {order.deliveryMethod}
                {order.deliveryMethod === 'Takeout' && ` (owes $${order.orderData.takeoutOwed})`}
              </td>
              <td className="border px-4 py-2">{order.address}</td>
              <td className="border px-4 py-2">{order.specialInstructions}</td>
              <td className="border px-4 py-2">{order.orderData.forTwo}</td>
              <td className="border px-4 py-2">{order.orderData.forFour}</td>
              <td className="border px-4 py-2">{order.orderData.forSix}</td>
              <td className="border px-4 py-2">{order.code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PrintView
