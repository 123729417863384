import React from 'react'
import TapfoodLogo from '../../assets/img/tapfood.png'

const TapfoodStamp = () => (
  <a
    className="fixed flex flex-col hidden text-center opacity-75 lg:inline"
    href="https://www.tapfood.us"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="mb-1 text-xs text-white">Powered By</span>
    <img src={TapfoodLogo} alt="Tapfood Logo" className="h-20 rounded bg-tap hover:bg-orange-800" />
  </a>
)

export default TapfoodStamp
