import { useState, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import { fetchRestaurant } from '../lib/db'

const useRestaurant = () => {
  const [restaurant, setRestaurant] = useState(null)
  const [loading, setLoading] = useState(true)

  const getRestaurant = useCallback(async restaurantId => {
    if (!restaurantId) {
      throw new Error('Must provide restaurant ID')
    }

    setLoading(true)

    try {
      const restaurant = await fetchRestaurant(restaurantId)
      setRestaurant(restaurant)
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, restaurant, getRestaurant }
}

export default createContainer(useRestaurant)
