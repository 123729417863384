import { useEffect } from 'react'

const Home = () => {
  useEffect(() => {
    window.location.href = 'https://tapfood.us'
  }, [])

  return null
}

export default Home
