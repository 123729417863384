import React, { useState } from 'react'
import Auth from '../../state/AuthState'
import Orders from './Orders'
import EditMenu from './EditMenu'
import EditGreeting from './EditGreeting'
import NewWeek from './NewWeek'
import MassEmails from './MassEmails'

const AdminPanel = () => {
  const [page, setPage] = useState('orders')
  const { logout } = Auth.useContainer()

  return (
    <>
      <header className="flex items-center justify-between bg-gray-300 border-b border-gray-400 w-100 p-4 shadow">
        <div>
          <span className="hidden md:inline mr-8 font-bold">Marigold's Admin</span>

          <span
            className={`mr-8 cursor-pointer hover:text-gray-700 ${page === 'orders' && 'font-bold'}`}
            onClick={() => setPage('orders')}
          >
            Orders
          </span>
          <span
            className={`mr-8 cursor-pointer hover:text-gray-700 ${page === 'edit-greeting' && 'font-bold'}`}
            onClick={() => setPage('edit-greeting')}
          >
            Edit Greeting
          </span>
          <span
            className={`mr-8 cursor-pointer hover:text-gray-700 ${page === 'edit-menu-info' && 'font-bold'}`}
            onClick={() => setPage('edit-menu-info')}
          >
            Edit Menus
          </span>
          <span
            className={`mr-8 cursor-pointer hover:text-gray-700 ${page === 'new-week' && 'font-bold'}`}
            onClick={() => setPage('new-week')}
          >
            Start New Week
          </span>
          <span
            className={`mr-8 cursor-pointer hover:text-gray-700 ${page === 'new-week' && 'font-bold'}`}
            onClick={() => setPage('mass-emails')}
          >
            Send Mass Emails
          </span>
        </div>
        <div>
          <span className="cursor-pointer hover:text-gray-700" onClick={logout}>
            Logout
          </span>
        </div>
      </header>
      {page === 'edit-menu-info' ? (
        <EditMenu />
      ) : page === 'edit-greeting' ? (
        <EditGreeting />
      ) : page === 'orders' ? (
        <Orders />
      ) : page === 'new-week' ? (
        <NewWeek />
      ) : page === 'mass-emails' ? (
        <MassEmails />
      ) : null}
    </>
  )
}

export default AdminPanel
