import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import jwt_decode from 'jwt-decode'

const useAuth = () => {
  const [user, setUser] = useState(null)
  const [loggingIn, setLoggingIn] = useState(true)

  useEffect(() => {
    const checkSession = async () => {
      const token = localStorage.getItem('marigolds')

      if (token) {
        const { exp, email } = jwt_decode(token)
        const currentTime = new Date().getTime() / 1000

        if (currentTime > exp) {
          localStorage.removeItem('marigolds')
        } else {
          setUser({ email })
        }
      }
      setLoggingIn(false)
    }

    checkSession()
  }, [])

  async function login({ token, email, id }) {
    localStorage.setItem('marigolds', token)
    setUser({ id, email })
  }

  function logout() {
    localStorage.removeItem('marigolds')
    window.location.href = '/marigolds/admin'
  }

  return { user, loggingIn, login, logout }
}

export default createContainer(useAuth)
