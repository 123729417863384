import React, { useState } from 'react'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default ({ onChange, initialHtml }) => {
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(initialHtml)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)
    return editorState
  })

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <Editor
      toolbar={{
        options: ['inline', 'fontSize', 'colorPicker'],
        inline: {
          options: ['bold', 'italic', 'underline']
        },
        colorPicker: {
          colors: ['black', 'transparent', '#fff']
        },
        fontSize: {
          options: [16, 18, 24, 30, 36, 48, 60, 72, 96]
        }
      }}
      wrapperClassName="h-full"
      editorState={editorState}
      toolbarClassName="mb-0"
      editorClassName="bg-white px-2 order border-top border-gray-300"
      onEditorStateChange={onEditorStateChange}
    />
  )
}
