import React, { useState, useEffect } from 'react'
import { fetchOrders } from '../../lib/admin'

const Orders = () => {
  const [orders, setOrders] = useState([])
  const [day, setDay] = useState('Tuesday')
  const [loading, setLoading] = useState(false)
  const [totals, setTotals] = useState({ 2: 0, 4: 0, 6: 0 })

  async function getOrders(day) {
    try {
      setLoading(true)
      const data = await fetchOrders(day)
      setOrders(data.orders)
      setDay(day)
      setTotals({ 2: data.total2, 4: data.total4, 6: data.total6 })
    } catch (err) {
      console.error(err)
      alert('Could not fetch orders.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrders('Tuesday')
  }, [])

  return (
    <div className="p-5">
      <div className="flex items-center justify-between">
        <div>
          <button
            className="bg-tap p-2 text-white mr-4 rounded hover:bg-orange-700"
            onClick={() => getOrders('Tuesday')}
          >
            See Tuesday
          </button>
          <button
            className="bg-tap p-2 text-white rounded hover:bg-orange-700"
            onClick={() => getOrders('Thursday')}
          >
            See Thursday
          </button>
        </div>
        <div>
          <a href="/marigolds/admin/print-view/Tuesday" target="_blank">
            <button className="bg-tap p-2 text-white mr-4 rounded hover:bg-orange-700">Print Tuesday</button>
          </a>
          <a href="/marigolds/admin/print-view/Thursday" target="_blank">
            <button className="bg-tap p-2 text-white mr-4 rounded hover:bg-orange-700">Print Thursday</button>
          </a>
        </div>
      </div>
      {loading ? (
        <p className="text-center mt-5">Loading orders...</p>
      ) : (
        <>
          <h1 className="mt-2 text-2xl">Orders for {day}</h1>
          <p className="mt-3 mb-5">{orders.length} total orders</p>
          <p className="mt-3">For Two's: {totals[2]}</p>
          <p className="mt-1">For Four's: {totals[4]}</p>
          <p className="mt-1 mb-5">For Six's: {totals[6]}</p>
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Delivery Method</th>
                <th>Address</th>
                <th>Special Instructions</th>
                <th>For 2</th>
                <th>For 4</th>
                <th>For 6</th>
                <th>Order #</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, i) => (
                <tr key={i}>
                  <td className="border px-4 py-2">{order.lastName}</td>
                  <td className="border px-4 py-2">{order.email}</td>
                  <td className="border px-4 py-2">{order.phone}</td>
                  <td className="border px-4 py-2">
                    {order.deliveryMethod}
                    {order.deliveryMethod === 'Takeout' && ` (owes $${order.orderData.takeoutOwed})`}
                  </td>
                  <td className="border px-4 py-2">{order.address}</td>
                  <td className="border px-4 py-2">{order.specialInstructions}</td>
                  <td className="border px-4 py-2">{order.orderData.forTwo}</td>
                  <td className="border px-4 py-2">{order.orderData.forFour}</td>
                  <td className="border px-4 py-2">{order.orderData.forSix}</td>
                  <td className="border px-4 py-2">{order.code}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default Orders
