import React from 'react'
import Footer from './Footer'
import TapfoodStamp from './TapfoodStamp'

const MainLayout = ({ children }) => {
  return (
    <main className="base-layout relative p-4 antialiased min-h-screen text-white">
      <TapfoodStamp />
      <div className="max-w-4xl mx-auto">
        {children}
        <Footer />
      </div>
    </main>
  )
}

export default MainLayout
