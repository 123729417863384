import React from 'react'

const QuantitySelector = ({ title, quantity, onDecrease, onIncrease }) => {
  return (
    <div className="flex flex-col items-center mb-2 mx-4 h-full">
      <small className="text-tap font-bold mb-2">{title}</small>
      <div className="flex items-center bg-white rounded shadow mb-2">
        <button
          className="bg-tap hover:bg-orange-600 p-3 text-white font-bold rounded-tl rounded-bl"
          onClick={onDecrease}
        >
          -
        </button>
        <div className="text-center font-bold" style={{ width: '45px' }}>
          {quantity}
        </div>
        <button
          className="bg-tap hover:bg-orange-600 p-3 text-white font-bold rounded-tr rounded-br"
          onClick={onIncrease}
        >
          +
        </button>
      </div>
    </div>
  )
}

export default QuantitySelector
