import React, { useState } from 'react'
import Editor from './Editor'
import { sendMassEmail } from '../../lib/admin'

const NewWeek = () => {
  const [emailHtml, setEmailHtml] = useState('')
  const [subject, setSubject] = useState('')

  return (
    <div className="bg-gray-200 min-h-screen p-5 text-gray-900">
      <div className="flex flex-col md:flex-row">
        <div className="w-4/5 h-56">
          <p className="font-bold text-lg mb-3">Send mass emails:</p>
          <div className="flex justify-between mb-2 items-center">
            <input
              placeholder="Subject"
              type="text"
              className="p-2 rounded flex-grow mr-2"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <button
              onClick={() => {
                sendMassEmail({
                  emailHtml,
                  subject,
                })
                  .then(() => alert('Successfully sent email to all subscribed customers!'))
                  .catch((err) => {
                    console.error(err)
                    alert(err.response?.data?.error || 'Could not send emails.')
                  })
              }}
              className="bg-tap hover:bg-orange-800 text-white p-2 rounded shadow"
            >
              Send Emails
            </button>
          </div>
          <Editor onChange={(html) => setEmailHtml(html)} initialHtml={emailHtml} />
        </div>
      </div>
    </div>
  )
}

export default NewWeek
