import React from 'react'
import Logo from '../../assets/img/tapfood.png'

const Loader = () => (
  <div className="flex items-center justify-center min-h-screen opacity-50">
    <img src={Logo} className="bg-tap rounded-lg h-32" alt="tapfood" />
  </div>
)

export default Loader
