import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MainLayout, Card } from '../components/common'
import { addEmail } from '../lib/db'
import { DualRing } from 'react-css-spinners'

const AddEmail = ({ history }) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await addEmail(email)
      setLoading(false)
      setEmail('')
      alert('Thanks! Your email has successfully been added to our mailing list!')
      window.location.href = 'https://tapfood.us/marigolds-summit-nj'
    } catch (err) {
      setLoading(false)
      console.error(err)
      alert('Something went wrong.')
    }
  }

  return (
    <MainLayout>
      <Card>
        <div className="flex flex-col items-center justify-center my-5">
          <Link to="/marigolds" className="text-blue-600 text-md mb-8">
            Back
          </Link>
          <p className="text-center text-lg mb-3">
            If you'd like to get weekly emails about new dinners to go, add your email to our mailing list!
          </p>
          <form className="flex flex-col items-center justify-center w-full" onSubmit={onSubmit}>
            <input
              required
              type="email"
              placeholder="Enter your email..."
              className="text-center rounded p-2 text-lg w-3/5 mb-3"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {loading ? (
              <>
                <DualRing color="red" />
                <p className="text-lg text-center">Please wait...</p>
              </>
            ) : (
              <button type="submit" className="bg-tap text-white text-lg p-2 rounded hover:bg-orange-600">
                Submit
              </button>
            )}
          </form>
        </div>
      </Card>
    </MainLayout>
  )
}

export default AddEmail
