import React from 'react'
import { withRouter } from 'react-router-dom'
import StripeCheckout from 'react-stripe-checkout'
import produce from 'immer'
import { Card } from '../common'
import { CartState } from '../../state'

const PaymentDetails = ({ submitOrder, history }) => {
  const { cart, setCart } = CartState.useContainer()

  const calculateCustomTip = () => {
    let tip = 0.09 * cart.priceDetails.subtotalDelivery
    if (tip < 4) {
      tip = 4
    }
    tip = tip * 2
    tip = Math.round(tip) / 2
    return `$${tip.toFixed(2)}`
  }

  const renderLabel = () => {
    let number =
      (cart.priceDetails.subtotalDelivery + cart.priceDetails.deliveryFee + cart.priceDetails.tip) * 100
    number = Math.round(number)
    number = number / 100
    return number.toFixed(2)
  }

  return (
    <Card>
      <div className="max-w-2xl mx-auto mb-1 p-4 flex">
        <div className="w-1/2 mr-2">
          <label className="text-gray-700 text-sm font-bold mb-1">Last Name</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Last Name"
            onChange={(e) =>
              setCart(
                produce(cart, (newCart) => {
                  newCart.orderDetails.name = e.target.value
                })
              )
            }
          />
        </div>
        <div className="w-1/2 ml-2">
          <label className="text-gray-700 text-sm font-bold mb-1">Email</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Email"
            onChange={(e) =>
              setCart(
                produce(cart, (newCart) => {
                  newCart.orderDetails.email = e.target.value
                })
              )
            }
          />
        </div>
      </div>
      {(cart.orderDetails.tue.deliveryMethod === 'Delivery' ||
        cart.orderDetails.thu.deliveryMethod === 'Delivery') && (
        <div className="max-w-sm mx-auto mb-5 p-4">
          <label className="text-gray-700 text-sm font-bold mb-1">Phone</label>
          <input
            className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Phone"
            value={cart.orderDetails.phone}
            onChange={(e) =>
              setCart(
                produce(cart, (newCart) => {
                  newCart.orderDetails.phone = e.target.value
                })
              )
            }
          />
        </div>
      )}

      {(cart.orderDetails.tue.deliveryMethod === 'Delivery' && cart.priceDetails.subtotalDelivery > 0) ||
      (cart.orderDetails.thu.deliveryMethod === 'Delivery' && cart.priceDetails.subtotalDelivery > 0) ? (
        <>
          <div className="max-w-2xl mx-auto mb-5 p-4 text-center">
            <p className="mb-4">Would you like to add a delivery tip?</p>
            <div>
              <button
                onClick={() =>
                  setCart(
                    produce(cart, (newCart) => {
                      newCart.priceDetails.tip = cart.priceDetails.subtotalDelivery * 0.1
                    })
                  )
                }
                className="bg-tap rounded-lg font-bold text-white shadow p-3 mr-3"
              >
                10%
              </button>
              <button
                onClick={() =>
                  setCart(
                    produce(cart, (newCart) => {
                      let tip = 0.09 * cart.priceDetails.subtotalDelivery
                      if (tip < 4) {
                        tip = 4
                      }
                      tip = tip * 2
                      tip = Math.round(tip) / 2
                      newCart.priceDetails.tip = tip
                    })
                  )
                }
                className="bg-tap rounded-lg font-bold text-white shadow p-3 mr-3"
              >
                {calculateCustomTip()}
              </button>
              <input
                onChange={(e) =>
                  setCart(
                    produce(cart, (newCart) => {
                      newCart.priceDetails.tip = Number(e.target.value)
                    })
                  )
                }
                type="number"
                className="rounded-lg text-center p-3"
                placeholder="Custom amount"
              />
            </div>
          </div>
          <div className="max-w-2xl mx-auto font-bold mb-5 p-4 text-center">
            <p className="mb-1">Tip: ${cart.priceDetails.tip.toFixed(2)}</p>
            <p className="mb-1">Delivery Fee: ${cart.priceDetails.deliveryFee.toFixed(2)}</p>
          </div>
        </>
      ) : null}
      {(cart.orderDetails.tue.deliveryMethod === 'Delivery' && cart.priceDetails.subtotalDelivery > 0) ||
      (cart.orderDetails.thu.deliveryMethod === 'Delivery' && cart.priceDetails.subtotalDelivery > 0) ? (
        <div className="font-bold text-center mb-1">Total due now for delivery: ${renderLabel()}</div>
      ) : null}
      <div className="font-bold text-center mb-2">
        Total due later in store for takeout: ${cart.priceDetails.subtotalTakeout.toFixed(2)}
      </div>

      <div className="text-center mb-5">
        {cart.priceDetails.subtotalDelivery === 0 ? (
          <button
            onClick={() => {
              submitOrder({ token: null, cart, history })
            }}
            className="bg-tap hover:bg-orange-600 shadow p-3 font-bold rounded text-white"
          >
            Place Takeout Order
          </button>
        ) : (
          <StripeCheckout
            token={(token) => submitOrder({ token, cart, history })}
            amount={Math.round(
              (cart.priceDetails.deliveryFee + cart.priceDetails.tip + cart.priceDetails.subtotalDelivery) *
                100
            )}
            name="Marigold's Order"
            description="Enter your payment details below"
            stripeKey="pk_live_mJjxbYyIi4QiU898VUxYWDfu"
            disabled={false}
            email={`${cart.orderDetails.phone}@tapfood.us`}
          />
        )}
      </div>
    </Card>
  )
}

export default withRouter(PaymentDetails)
