import React from 'react'
import produce from 'immer'
import QuantitySelector from './QuantitySelector'
import { Card } from '../common'
import { RestaurantState, CartState } from '../../state'

const Menu = ({ day }) => {
  const { cart, setCart, decrease, increase } = CartState.useContainer()
  const { restaurant } = RestaurantState.useContainer()

  return (
    <Card>
      <div className="max-w-2xl mx-auto p-4">
        <div
          className="html text-left mb-5"
          dangerouslySetInnerHTML={{ __html: restaurant.menuData[`${day}Html`] }}
        />
        <p
          className={`text-xl text-center font-bold w-full mb-4 text-red-600 ${
            restaurant[`${day}InventoryAmount`] > 30 && 'opacity-0'
          }`}
        >
          {restaurant[`${day}InventoryAmount`] === 0
            ? 'SOLD OUT'
            : `${restaurant[`${day}InventoryAmount`]} dinners available`}
        </p>
        {restaurant[`${day}InventoryAmount`] > 0 && (
          <>
            <div className="flex flex-col md:flex-row items-center justify-center">
              <QuantitySelector
                quantity={cart.orderDetails[day].forTwo}
                title={`Dinner for 2 ($${restaurant.menuData.forTwoPrice})`}
                onDecrease={() => decrease(day, 'forTwo')}
                onIncrease={() => increase(day, 'forTwo')}
              />
              <QuantitySelector
                quantity={cart.orderDetails[day].forFour}
                title={`Dinner for 4 ($${restaurant.menuData.forFourPrice})`}
                onDecrease={() => decrease(day, 'forFour')}
                onIncrease={() => increase(day, 'forFour')}
              />
              <QuantitySelector
                quantity={cart.orderDetails[day].forSix}
                title={`Dinner for 6 ($${restaurant.menuData.forSixPrice})`}
                onDecrease={() => decrease(day, 'forSix')}
                onIncrease={() => increase(day, 'forSix')}
              />
            </div>

            <div>
              <div className="w-full">
                <div className="mt-2 text-center my-3">
                  <label className="inline-flex items-center mr-8">
                    <input
                      type="radio"
                      value="Delivery"
                      checked={cart.orderDetails[day].deliveryMethod === 'Delivery'}
                      onChange={(e) =>
                        setCart(
                          produce(cart, (newCart) => {
                            newCart.orderDetails[day].deliveryMethod = e.target.value
                          })
                        )
                      }
                    />
                    <span className="ml-2 text-2xl">Delivery</span>
                  </label>
                  <label className="inline-flex items-center ml-8">
                    <input
                      type="radio"
                      value="Takeout"
                      checked={cart.orderDetails[day].deliveryMethod === 'Takeout'}
                      onChange={(e) =>
                        setCart(
                          produce(cart, (newCart) => {
                            newCart.orderDetails[day].deliveryMethod = e.target.value
                          })
                        )
                      }
                    />
                    <span className="ml-2 text-2xl">Takeout</span>
                  </label>
                </div>
                <div className="mb-5">
                  <label className="text-gray-700 text-sm font-bold mb-1">Special Instructions</label>
                  <input
                    className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Special Instructions"
                    value={cart.orderDetails[day].specialInstructions}
                    onChange={(e) =>
                      setCart(
                        produce(cart, (newCart) => {
                          newCart.orderDetails[day].specialInstructions = e.target.value
                        })
                      )
                    }
                  />
                </div>
                {cart.orderDetails[day].deliveryMethod === 'Delivery' && (
                  <>
                    {cart.orderDetails.tue.address !== '' &&
                      day === 'thu' &&
                      cart.orderDetails.tue.deliveryMethod === 'Delivery' &&
                      cart.orderDetails.thu.deliveryMethod === 'Delivery' && (
                        <div className="mb-1">
                          <span
                            className="hover:cursor-pointer text-blue-400 font-bold"
                            onClick={() =>
                              setCart(
                                produce(cart, (newCart) => {
                                  newCart.orderDetails.thu.address = cart.orderDetails.tue.address
                                })
                              )
                            }
                          >
                            Click here to autofill information
                          </span>
                        </div>
                      )}
                    <div className="flex">
                      <div className="w-3/5 mb-5 pr-3">
                        <label className="text-gray-700 text-sm font-bold mb-1">Address</label>
                        <input
                          className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          placeholder="Address"
                          value={cart.orderDetails[day].address}
                          onChange={(e) =>
                            setCart(
                              produce(cart, (newCart) => {
                                newCart.orderDetails[day].address = e.target.value
                              })
                            )
                          }
                        />
                      </div>
                      <div className="w-2/5 mb-5">
                        <label className="text-gray-700 text-sm font-bold mb-1">City</label>
                        <input
                          className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          placeholder="City"
                          value={cart.orderDetails[day].city}
                          onChange={(e) =>
                            setCart(
                              produce(cart, (newCart) => {
                                newCart.orderDetails[day].city = e.target.value
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  )
}

export default Menu
