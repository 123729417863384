import React, { useEffect } from 'react'
import { RestaurantState } from '../state'
import { MainLayout, Loader } from '../components/common'
import { Greeting, Menu, PaymentDetails } from '../components/ordering'
import { submitOrder } from '../lib/payments'

const Marigolds = () => {
  const { loading, getRestaurant } = RestaurantState.useContainer()

  useEffect(() => {
    getRestaurant('marigolds')
  }, [getRestaurant])

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Greeting />
          <Menu day="tue" />
          <Menu day="thu" />
          <PaymentDetails submitOrder={submitOrder} />
        </>
      )}
    </MainLayout>
  )
}

export default Marigolds
