import { useState, useEffect } from 'react'
import produce from 'immer'
import { createContainer } from 'unstated-next'
import RestaurantState from './RestaurantState'

const DELIVERY_FEE = 7

const INITIAL_CART = {
  orderDetails: {
    name: '',
    email: '',
    phone: '',
    tue: {
      specialInstructions: '',
      deliveryMethod: 'Takeout',
      address: '',
      city: '',
      forTwo: 0,
      forFour: 0,
      forSix: 0,
    },
    thu: {
      specialInstructions: '',
      deliveryMethod: 'Takeout',
      address: '',
      city: '',
      forTwo: 0,
      forFour: 0,
      forSix: 0,
    },
  },
  priceDetails: {
    subtotalDelivery: 0,
    subtotalTakeout: 0,
    deliveryFee: DELIVERY_FEE,
    tip: 0,
  },
}

const useCart = () => {
  const [cart, setCart] = useState(INITIAL_CART)
  const { restaurant } = RestaurantState.useContainer()

  useEffect(() => {
    const calcTuesdaySubtotal = () => {
      let subtotal = 0
      subtotal += restaurant.menuData.forTwoPrice * cart.orderDetails.tue.forTwo
      subtotal += restaurant.menuData.forFourPrice * cart.orderDetails.tue.forFour
      subtotal += restaurant.menuData.forSixPrice * cart.orderDetails.tue.forSix
      return subtotal
    }

    const calcThursdaySubtotal = () => {
      let subtotal = 0
      subtotal += restaurant.menuData.forTwoPrice * cart.orderDetails.thu.forTwo
      subtotal += restaurant.menuData.forFourPrice * cart.orderDetails.thu.forFour
      subtotal += restaurant.menuData.forSixPrice * cart.orderDetails.thu.forSix
      return subtotal
    }

    if (restaurant) {
      let subtotalDelivery = 0
      let subtotalTakeout = 0
      let deliveryFee = 0

      if (cart.orderDetails.tue.deliveryMethod === 'Delivery') {
        subtotalDelivery += calcTuesdaySubtotal()
        deliveryFee += DELIVERY_FEE
      }
      if (cart.orderDetails.thu.deliveryMethod === 'Delivery') {
        subtotalDelivery += calcThursdaySubtotal()
        deliveryFee += DELIVERY_FEE
      }
      if (cart.orderDetails.tue.deliveryMethod === 'Takeout') {
        subtotalTakeout += calcTuesdaySubtotal()
      }
      if (cart.orderDetails.thu.deliveryMethod === 'Takeout') {
        subtotalTakeout += calcThursdaySubtotal()
      }

      setCart(
        produce(cart, (newCart) => {
          newCart.priceDetails.subtotalDelivery = subtotalDelivery
          newCart.priceDetails.subtotalTakeout = subtotalTakeout
          newCart.priceDetails.deliveryFee = deliveryFee
        })
      )
    }
  }, [cart, restaurant])

  const decrease = (day, itemKey) => {
    if (cart.orderDetails[day][itemKey] > 0) {
      setCart(
        produce(cart, (newCart) => {
          newCart.orderDetails[day][itemKey]--
        })
      )
    }
  }

  const increase = (day, itemKey) => {
    if (cart.orderDetails[day][itemKey] < 2) {
      setCart(
        produce(cart, (newCart) => {
          newCart.orderDetails[day][itemKey]++
        })
      )
    }
  }

  return { cart, setCart, decrease, increase }
}

export default createContainer(useCart)
