import React from 'react'
import { RestaurantState } from '../../state'

const Footer = () => {
  const { restaurant } = RestaurantState.useContainer()

  return (
    <footer className="text-center text-sm mt-10">
      <span className="">
        &copy; {new Date().getFullYear()}{' '}
        {restaurant && restaurant.websiteUrl && (
          <a
            className="hover:text-yellow-400 fade underline"
            href={restaurant && restaurant.websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Marigolds
          </a>
        )}{' '}
        |{' '}
      </span>
      <span>
        Built by{' '}
        <a
          className="hover:text-yellow-400 fade underline"
          href="https://zachweinberg.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zach Weinberg
        </a>
      </span>
    </footer>
  )
}

export default Footer
