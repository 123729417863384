import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import {
  HomePage,
  MarigoldsPage,
  MarigoldsAdminPage,
  ThankYouPage,
  PrintViewPage,
  AddEmailPage
} from './pages'
import { AppProvider } from './state'

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/marigolds/add-email" component={AddEmailPage} />
          <Route exact path="/marigolds" component={MarigoldsPage} />
          <Route exact path="/marigolds/admin" component={MarigoldsAdminPage} />
          <Route exact path="/marigolds/admin/print-view/:day" component={PrintViewPage} />
          <Route exact path="/thank-you" component={ThankYouPage} />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  )
}

export default App
