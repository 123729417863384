import axios from 'axios'

export async function fetchRestaurant(restaurantId) {
  try {
    const { data } = await axios.get(`/api/restaurants/${restaurantId}`)

    if (data.status === 'ok') {
      return data.restaurant
    }
  } catch (err) {
    throw err
  }
}

export async function addEmail(email) {
  try {
    await axios.post('/api/misc/add-email', { email })
  } catch (err) {
    throw err
  }
}
