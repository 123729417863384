import React, { useEffect } from 'react'
import { LoginForm, AdminPanel } from '../components/admin'
import { AuthState, RestaurantState } from '../state'

const MarigoldsAdmin = () => {
  const { user, loggingIn } = AuthState.useContainer()
  const { loading, getRestaurant } = RestaurantState.useContainer()

  useEffect(() => {
    getRestaurant('marigolds')
    // eslint-disable-next-line
  }, [])

  return loading || loggingIn ? (
    <p className="text-center mt-10">Loading...</p>
  ) : user ? (
    <AdminPanel />
  ) : (
    <LoginForm />
  )
}

export default MarigoldsAdmin
