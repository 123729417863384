import React, { useState } from 'react'
import Auth from '../../state/AuthState'
import axios from 'axios'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const { login } = Auth.useContainer()

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const { data } = await axios.post('/api/admin/login', { email, password })

      if (data.status === 'ok') {
        setLoading(true)
        login({ token: data.token, id: data.id, email: data.email })
      }
    } catch (err) {
      console.error(err.message)
      setLoading(false)
      alert(err.response.data.error)
    }
  }

  return (
    <div className="min-h-screen bg-gray-300">
      <div className="max-w-md mx-auto pt-10">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={onSubmit}>
          <p className="mb-4 text-gray-700 font-bold">Welcome back, Mary!</p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {loading ? 'Loading...' : 'Sign In'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
